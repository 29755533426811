import { Row } from 'antd'
import React from 'react'
import QuestComp from './QuestComp'
import { useTranslation } from 'react-i18next'

type Props = {
  levelList: any
  currentLevelIndex: number
}
const QuestHall: React.FC<Props> = ({ levelList, currentLevelIndex }) => {
  const { t } = useTranslation()
  return (
    <div style={{ marginTop: '1rem' }}>
      <span style={{ fontSize: '16px' }}>{t('quest_lobby')}</span>
      <Row style={{ marginTop: '1rem' }}>
        {levelList?.map((item: any, index: number) => {
          const isValid = index <= currentLevelIndex
          const isUpgradeable = index == currentLevelIndex + 1
          const isInvalid = index > currentLevelIndex && !isUpgradeable

          return <QuestComp data={item} key={item.key} isValid={isValid} />
        })}
      </Row>
    </div>
  )
}
export default QuestHall
