import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/store/store'
import { requestUpdateBankInfor, requestGetListBank } from 'services/api/AccountApi'
import { UserBankNames } from 'utils/constants'
import history from 'utils/history'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'

const BankCardPage: React.FC = () => {
  const [listBank, setListBank] = useState<any>([])
  const { Option } = Select
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const { t } = useTranslation()
  const onFinish = async (values: any) => {
    const resData = (
      await requestUpdateBankInfor({
        full_name: values.name,
        phone: values.phone,
        bank_number: values.bankNumber,
        bank_name: values.bankName,
      })
    ).data

    if (resData.bank) {
      message.success('Cập nhật thông tin thẻ ngân hàng thành công')
      history.push(ADMIN_ROUTER_PATH.PROFILE)
    }
  }

  const replaceAt = (replacement: any) => {
    let str = replacement.split('')
    str = str.map((element: any, index: any) => {
      if (index > str.length - 4) return (element = '*')
      return element
    })
    console.log('str', str.join(''))

    return str.join('')
  }

  const getListBank = async () => {
    try {
      // const res = await requestGetListProductVip(key);
      const res = await requestGetListBank({ limit: 1000 });
      if (res) {
        setListBank(res?.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getListBank()
  }, [])

  return (
    <Styled.Container>
      <Styled.Title>{t('my_bank_card')}</Styled.Title>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          name: userInfo?.bank ? userInfo?.bank?.full_name : '',
          bankNumber: userInfo?.bank
            ? replaceAt(userInfo?.bank?.bank_number)
            : '',
          bankName: userInfo?.bank ? userInfo?.bank?.bank_name : '',
          phone: userInfo?.bank ? replaceAt(userInfo?.bank?.phone) : '',
        }}
        onFinish={onFinish}
        autoComplete="off"
        style={{ marginTop: '1rem' }}
      >
        <Form.Item
          label={t('full_name')}
          name="name"
          rules={[{ required: true, message: t('not_empty') }]}
        >
          <Input
            disabled={userInfo?.bank?.full_name}
            placeholder={t('full_name')}
          />
        </Form.Item>
        <Form.Item
          label={t('phone')}
          name="phone"
          rules={[{ required: true, message: t('not_empty') }]}
        >
          <Input disabled={userInfo?.bank?.phone} placeholder={t('phone')} />
        </Form.Item>
        <Form.Item
          label={t('number_card')}
          name="bankNumber"
          rules={[{ required: true, message: t('not_empty') }]}
        >
          <Input
            disabled={userInfo?.bank?.bank_number}
            placeholder={t('number_card')}
          />
        </Form.Item>
        <Form.Item
          name={'bankName'}
          label={t('bank_name')}
          rules={[{ required: true, message: t('not_empty') }]}
        >
          <Select
            disabled={userInfo?.bank?.bank_name}
            showSearch
            placeholder={t('bank_name')}
            optionFilterProp="children"
            onChange={() => { }}
            filterOption={(input, option) =>
              (option!.value as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {/* {Object.values(UserBankNames).map(bank => (
              <Option value={bank}>{bank}</Option>
            ))} */}

            {listBank.map((item: any) => {
              return (
                <Option value={item.name}>
                  {
                    <Row>
                      <Col>
                        {item.name}{" "}
                      </Col>
                    </Row>
                  }
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <span style={{ color: 'red' }}>{t('note_update_info_bank')}</span>
        {!userInfo?.bank ? (
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button style={{ marginTop: 12 }} type="primary" htmlType="submit">
              {t('confirm_info')}
            </Button>
          </Form.Item>
        ) : null}
      </Form>
    </Styled.Container>
  )
}
export default BankCardPage
